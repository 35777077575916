import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';

// @ts-ignore
let root = "APP";
if (window.location.pathname.startsWith('/widget'))
    root = "EMBED"
if (window.location.pathname.startsWith('/email'))
    root = "EMBED"
const App = React.lazy(() => import('./App'));
const EmbedApp = React.lazy(() => import('./EmbedApp'));
if (root === 'APP')
    ReactDOM.render(
      <React.StrictMode>
          <React.Suspense fallback={<p></p>}><App /></React.Suspense>
      </React.StrictMode>,
      document.getElementById('root')
    );
if (root === "EMBED")
    ReactDOM.render(
      <React.StrictMode>
          <React.Suspense fallback={<p></p>}><EmbedApp /></React.Suspense>
      </React.StrictMode>,
      document.getElementById('root')
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
